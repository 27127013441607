import axios from 'axios';
import VALIDATIONS from '../../apiPublic/ressources/validations';

function baseUrl() {
  const isProd = ['admin.hosman.co', 'app.hosman.co'].includes(window.location.host)
  let url = `${window.location.origin.replace('app.', '')}/api/v1`;
  if (isProd) url = url.replace('hosman.co', 'www.hosman.co')

  return url;
}

export const validatePhoneNumber = async(countryCode, number) => {
  const params = { full_phone: { phone_number: number, phone_country_code: countryCode } };

  const response = await VALIDATIONS.phoneNumber(params);
  return response.data;
};

export const validateEmail = (email) => {
  const EMAIL_REGEX = /^([A-Za-z0-9]+_+|[A-Za-z0-9]+-+|[A-Za-z0-9]+\.+|[A-Za-z0-9]+\++)*[A-Za-z0-9\-_]+@((\w+-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/i;

  return EMAIL_REGEX.test(email);
};

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Error404 from './Error404';

const CustomRoutes = ({ defaultRoute, children }) => {
  const errorElement = () => {
    if (defaultRoute) return defaultRoute

    return <Error404 />
  }

  return (
    <Routes>
      {children}

      <Route path='*' element={errorElement()} />
    </Routes>
  )
}

export default CustomRoutes;

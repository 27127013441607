import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_CircleCheck } from 'hosman-material';

const OfferPriceCards = ({ bgColor }) => {
  const offerPriceCards = [
    { title: 'Signature du mandat', text: '200€ offerts'},
    { title: 'Promesse de vente', text: '400€ offerts'},
  ]

  return (
    <>
      {offerPriceCards.map(({title, text}, index) => (
        <div key={index} className='w-fit'>
          <div className={`${bgColor} border border-ih-green-40 rounded-3xl p-4 text-ih-indigo flex flex-col gap-1`}>
            <p className='font-medium text-center'>{title}</p>

            <p className='flex items-center gap-2 font-semibold text-3xl italic'>
              <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green' size='xs' />
              {text}
            </p>
          </div>
        </div>
      ))}
    </>
  )
};

export default OfferPriceCards;

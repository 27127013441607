import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { distanceBetween } from '../../../../../utils/helpers/distances'

import PropertyCard from '../../../../../../shared/components/Property/PropertyCard';

import { Label } from 'hosman-material';

import PROPERTIES from '../../../../../apiPublic/ressources/properties'

const HosmanProperties = ({ sale, comparableSales }) => {
  const [properties, setProperties] = useState([])

  useEffect(() => {
    comparableSales.map((sale) => {
      PROPERTIES.getProperty(sale.property_id).then((response) => {
        setProperties((prevState) => ([...prevState, response.data]))
      });
    })
  }, []);

  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-6 justify-around mx-auto w-4/5'>
      {properties.map((property) => {
        return (
          <div key={property.id}>
            <PropertyCard
              property={property}
              stateLabel={property.sale.advert_label_sold && <Label color='bg-ih-green'>{property.sale.advert_label_sold}</Label>}
              centerLabel={<Label color='bg-ih-indigo'>A {distanceBetween(
                property.lat,
                property.lng,
                sale.property.lat,
                sale.property.lng
              )}</Label>}
              price={property.sale.accepted_offer_price || property.sale.price}
              areaPrice
            />
          </div>
        )
      })}
    </div>
  );
}

export default HosmanProperties;

HosmanProperties.propTypes = {
  sale: PropTypes.object.isRequired,
  comparableSales: PropTypes.array,
};

import React from 'react';
import PropTypes from 'prop-types';
import { timeSince } from '../../../../../utils/formattedDates';

import Characteristics from '../../../../../components/Property/Characteristics';

const YanportProperties = ({ properties }) => {
  const propertyType = (type) => {
    switch (type) {
      case 'APARTMENT':
        return 'Appartement'
      case 'HOUSE':
        return 'Maison'
      case 'PARKING':
        return 'Parking'
    }
  }

  return (
    <div className='flex flex-col gap-6 md:w-3/4 mx-auto'>
      {properties.map((property) => {
        return (
          <div key={property.id} className='bg-ih-blue-5 rounded-3xl'>
            <div className='flex flex-col md:flex-row items-center md:h-44'>
              <img src={property.features.visual.images[0]} className='w-full md:w-auto md:h-full object-cover aspect-[4/3] rounded-xl' />

              <div className='flex flex-col gap-3 p-6'>
                <div className='flex gap-2 font-semibold'>
                  <p>{propertyType(property.type)}</p>
                  <p>{property.features.geometry.roomCount} pièce{property.features.geometry.roomCount > 1 ? 's' : ''}</p>
                  <p>{property.features.geometry.surface} m²</p>
                </div>

                <p className='text-sm line-clamp-3'>{property.features.descriptive.description}</p>

                <Characteristics property={{
                  kind: property.type.toLowerCase(),
                  floor: property.features.geometry.floors[0]?.level,
                  total_floor: property.features.geometry.floorCount === 0 ? property.features.geometry.floors[0]?.level : property.features.geometry.floorCount,
                  carrez_area: property.features.geometry.surface,
                  room_number: property.features.geometry.roomCount,
                  bedroom_number: property.features.geometry.areaCount['BATHROOM']
                }} />

                <div className='flex flex-col md:flex-row justify-between gap-3'>
                  <div className='flex gap-3'>
                    <p className='h4 text-ih-blue'>{property.marketing.price.toLocaleString()} €</p>
                    <p className='text-sm'>{Math.floor(property.marketing.price/property.features.geometry.surface).toLocaleString()} €/m²</p>
                  </div>

                  <p className='text-sm'>Publié {timeSince(new Date(property.marketing.publicationStartDate))}</p>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  );
}

export default YanportProperties;

YanportProperties.propTypes = {
  properties: PropTypes.array
};

import axios from 'axios';
import client from '../client';

const isProd = window.location.host.includes('hosman.co') && !window.location.host.includes('staging')
let BASE_URL = `${window.location.origin.replace('app.', '').replace('admin.', '')}/api/v1`;
if (isProd) BASE_URL = BASE_URL.replace('hosman.co', 'www.hosman.co')

const config = { headers: { 'Access-Control-Allow-Origin': '*' }};

const PROPERTIES = {
  getPropertiesCoordinates(params) {
    return axios.get(`${BASE_URL}/properties/coordinates?${querifyObject({ q: params })}`, config)
  },

  getProperties(params) {
    return axios.get(`${BASE_URL}/properties?${querifyObject({ q: params })}`, config)
  },

  getProperty(id) {
    return client.get(`/properties/${id}`)
  },
}

export default PROPERTIES;


function querifyObject(object, parent = null) {
  return Object.keys(object)
    .map((key) => {
      const value = object[key];
      const full_key = fullKey(key, parent);

      if (Array.isArray(value)) return value.map((v) => toQuery(full_key + '[]', v)).join('&');
      else if (typeof value === 'object') return querifyObject(value, full_key);
      else return toQuery(full_key, value);
    })
    .join('&');
}

function toQuery(key, value) {
  return encodeURIComponent(key) + '=' + encodeURIComponent(value);
}

function fullKey(key, parent) {
  if (parent) return parent + '[' + key + ']';
  else return key;
}

export const logicImmo = 'https://res.cloudinary.com/vesta-home/image/upload/v1689245221/assets/Napoleon/Logos/logicImmo.webp'
export const figaroImmo = 'https://res.cloudinary.com/vesta-home/image/upload/v1689245221/assets/Napoleon/Logos/figaroImmo.webp'
export const jinka = 'https://res.cloudinary.com/vesta-home/image/upload/v1689245221/assets/Napoleon/Logos/jinka.webp'
export const bienici = 'https://res.cloudinary.com/vesta-home/image/upload/v1689245221/assets/Napoleon/Logos/bienici.webp'
export const seloger = 'https://res.cloudinary.com/vesta-home/image/upload/v1689245221/assets/Napoleon/Logos/seloger.webp'
export const leboncoin = 'https://res.cloudinary.com/vesta-home/image/upload/v1689245221/assets/Napoleon/Logos/leboncoin.webp'
export const bellesDemeures = 'https://res.cloudinary.com/vesta-home/image/upload/v1693907982/assets/Napoleon/Logos/bellesDemeures.webp'
export const proprieteLeFigaro = 'https://res.cloudinary.com/vesta-home/image/upload/v1693907982/assets/Napoleon/Logos/properieteLeFigaro.webp'
export const luxResidence = 'https://res.cloudinary.com/vesta-home/image/upload/v1693907982/assets/Napoleon/Logos/luxResidence.webp'
export const monchasseurImmo = 'https://res.cloudinary.com/vesta-home/image/upload/v1689245203/assets/Napoleon/Logos/monchasseurimmo.webp'
export const perlerare = 'https://res.cloudinary.com/vesta-home/image/upload/v1689245203/assets/Napoleon/Logos/perlerare.webp'
export const jerevedunemaison = 'https://res.cloudinary.com/vesta-home/image/upload/v1689245203/assets/Napoleon/Logos/jerevedunemaison.webp'
export const masteos = 'https://res.cloudinary.com/vesta-home/image/upload/v1689245202/assets/Napoleon/Logos/masteos.webp'
export const homeselect = 'https://res.cloudinary.com/vesta-home/image/upload/v1689245202/assets/Napoleon/Logos/homeselect.webp'
export const beanstock = 'https://res.cloudinary.com/vesta-home/image/upload/v1689245202/assets/Napoleon/Logos/beanstock.webp'
export const trustpilot = 'https://res.cloudinary.com/vesta-home/image/upload/v1689253759/assets/Napoleon/Logos/trustpilot.webp'
export const trustpilot_five_stars = 'https://res.cloudinary.com/vesta-home/image/upload/v1689255113/assets/Napoleon/Logos/trustpilot_five_stars.webp'

import React from 'react';
import PropTypes from 'prop-types';

const TitleBanner = ({ number, title }) => {
  return (
    <div className='flex justify-center items-center gap-2 lg:gap-6 bg-ih-indigo text-white h2 py-4 z-20' data-testid='TitleBanner'>
      <div className='relative md:text-8xl'>
        <p className='italic'>{number}.</p>
        <p className='absolute top-1 -right-2 italic text-white text-opacity-30'>{number}.</p>
      </div>

      <p className='font-thin md:text-6xl'>/</p>
      <p className='md:text-6xl'>{title}</p>
    </div>
  );
}

export default TitleBanner;

TitleBanner.propTypes = {
  number: PropTypes.string,
  title: PropTypes.string
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const AvatarsCounter = ({ count }) => {
  const [users, setUsers] = useState([])

  const fetchRandomUsers = async () => {
    const response = await axios.get('https://randomuser.me/api/?inc=picture&results=4')

    setUsers(response.data.results)
  };

  useEffect(() => {
    fetchRandomUsers()

    return function cleanup() {
      setUsers([])
    };
  }, []);

  return (
    <div className='flex justify-center' data-testid='AvatarsCounter'>
      {users.map((user, index) => {
        return (
          <img key={index} src={user.picture.thumbnail} className='w-12 h-12 object-cover rounded-full filter blur-[1px] -mx-2 border-2 border-ih-blue' alt={`profilePic-${index}`} />
        )
      })}


      {count > 4 &&
        <div className='w-12 h-12 rounded-full bg-ih-blue -mx-2 z-10'>
          <p className='h-12 flex justify-center items-center text-white h4'>+{count-4}</p>
        </div>
      }
    </div>
  )
};

export default AvatarsCounter;

AvatarsCounter.propTypes = {
  count: PropTypes.number.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';

const BuyerBaseGraph = ({ items }) => {
  return (
    <div data-testid='BuyerBaseGraph'>
      <div className='flex flex-col gap-5 relative'>
        <div className='grid grid-cols-3 text-sm font-semibold text-ih-indigo'>
          <div className='mx-auto relative'>
            <p className='w-max border-2 border-ih-indigo px-2 py-1 rounded-lg'>{items.low.estimation?.toLocaleString()} €</p>
            <div className='absolute -bottom-[5px] left-1/2 taransform -translate-x-1/2 rotate-45 h-3 w-3 bg-white border-r-2 border-b-2 border-ih-indigo rounded-br-[3px] z-10'></div>
          </div>
          <div className='mx-auto relative'>
            <p className='w-max border-2 border-ih-blue px-2 py-1 rounded-lg bg-ih-blue text-white'>{items.medium.estimation?.toLocaleString()} €</p>
            <div className='absolute -bottom-[5px] left-1/2 taransform -translate-x-1/2 rotate-45 h-3 w-3 bg-ih-blue border-r-2 border-b-2 border-ih-blue rounded-br-[3px] z-10'></div>
          </div>
          <div className='mx-auto relative'>
            <p className='w-max border-2 border-ih-indigo px-2 py-1 rounded-lg'>{items.high.estimation?.toLocaleString()} €</p>
            <div className='absolute -bottom-[5px] left-1/2 taransform -translate-x-1/2 rotate-45 h-3 w-3 bg-white border-r-2 border-b-2 border-ih-indigo rounded-br-[3px] z-10'></div>
          </div>
        </div>
        <div className='relative'>
          <div className='w-2/3 absolute top-3 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <span className='progress-bar h-4 w-full bg-ih-purple-20 bg-opacity-40 absolute'></span>
            <span className='progress-bar h-[1px] w-full bg-ih-blue absolute'></span>
            <span className='progress-bar h-[1px] w-full bg-ih-blue absolute top-4'></span>
          </div>
          <div className='grid grid-cols-3'>
            <div className='m-auto z-10'>
              <div className='flex items-center justify-around bg-ih-indigo w-11 h-11 rounded-full'>
                <p className='text-white font-semibold italic'>{items.low.buyerBase}</p>
              </div>
            </div>
            <div className='m-auto z-10'>
              <div className='flex items-center justify-around bg-ih-blue w-11 h-11 rounded-full'>
                <p className='text-white font-semibold italic'>{items.medium.buyerBase}</p>
              </div>
            </div>
            <div className='m-auto z-10'>
              <div className='flex items-center justify-around bg-ih-indigo w-11 h-11 rounded-full'>
                <p className='text-white font-semibold italic'>{items.high.buyerBase}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-3 text-center h4'>
          <p><span className='text-ih-green'>+ {items.low.buyerBase - items.medium.buyerBase} acquéreurs</span> potentiels par rapport au prix de marché</p>
          <p><span className='text-ih-blue'>{items.medium.buyerBase} acquéreurs</span> potentiels au prix de marché</p>
          <p><span className='text-ih-red'>- {items.medium.buyerBase - items.high.buyerBase} acquéreurs</span> potentiels par rapport au prix de marché</p>
        </div>
      </div>
    </div>
  )
}

export default BuyerBaseGraph;

BuyerBaseGraph.propTypes = {
  items: PropTypes.object
};

import React from 'react';
import PropTypes from 'prop-types';

import { BulletPoint, RoundedTopImage, InfoBox } from 'hosman-material';

const StrenghtsAndWeaknesses = ({ strengths, weaknesses, propertyPictureUrl, avatarPictureUrl }) => {
  return (
    <div className='flex flex-col gap-8 md:w-3/4 mx-auto' data-testid='StrenghtsAndWeaknesses'>
      <p className='h2 md:w-2/3 mx-auto text-center'>Aspects <span className='text-ih-blue'>remarquables</span> & contraignants de votre bien</p>

      <div className='md:w-4/5 mx-auto'>
        <InfoBox avatarSrc={avatarPictureUrl}>
          Les aspects contraignants ne seront pas mis en avant sur l'annonce mais doivent être pris en compte dans l'estimation de votre bien
        </InfoBox>
      </div>

      <div className='flex justify-evenly items-center gap-8'>
        <div className='flex flex-col gap-8 text-ih-neutral'>
          {strengths.length >= 1 &&
            <div className='flex flex-col gap-2'>
              <p className='h3'>Les aspects remarquables</p>
              {strengths.map((strongPoint, index) => {
                return(
                  <div key={index} className='flex items-center subtitle' data-testid='strength'>
                    <BulletPoint color='bg-ih-green' size='w-3 h-3' />

                    <p>{strongPoint.name}</p>
                  </div>
                )
              })}
            </div>
          }

          {weaknesses.length >= 1 &&
            <div className='flex flex-col gap-2'>
              <p className='h3'>Les aspects contraignants</p>
              {weaknesses.map((weakness, index) => {
                return(
                  <div key={index} className='flex items-center subtitle' data-testid='weakness'>
                    <BulletPoint color='bg-ih-yellow' size='w-3 h-3' />

                    <p>{weakness.name}</p>
                  </div>
                )
              })}
            </div>
          }
        </div>

        <div className='hidden md:block w-80'>
          <RoundedTopImage src={propertyPictureUrl} />
        </div>
      </div>
    </div>
  );
}

export default StrenghtsAndWeaknesses;

// PropTypes
StrenghtsAndWeaknesses.propTypes = {
  strengths: PropTypes.array,
  weaknesses: PropTypes.array,
  propertyPictureUrl: PropTypes.string,
  avatarPictureUrl: PropTypes.string

}

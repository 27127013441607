import React from 'react';
import { highVoltage, raisingHands, womanTechnologist } from '../../../../../assets/emojis'

import { Box, Emoji } from 'hosman-material'

const SimpleExperience = () => {
  const items = [
    {
      emoji: highVoltage,
      title: <>Vous <span className='text-ih-blue'>vendez bien</span></>,
      text: <>Nos experts immobiliers <span className='font-semibold'>prennent en charge votre vente de A à Z </span>: de l'estimation à la signature chez le notaire</>
    },
    {
      emoji: womanTechnologist,
      title: <>Vous <span className='text-ih-blue'>suivez tout</span></>,
      text: <>Votre <span className='font-semibold'>espace en ligne</span> vous permet de suivre votre projet de vente en temps réel (suivi des visites, des offres...)</>
    },
    {
      emoji: raisingHands,
      title: <>Vous <span className='text-ih-blue'>payez le bon prix</span></>,
      text: <>Notre <span className='font-semibold'>tarif fixe et 2x moins</span> cher qu'en agence traditionnelle vous permet d'augmenter votre net vendeur</>
    }
  ]

  return (
    <div className='flex flex-col gap-8 bg-hosman-gradient-1 py-6'>
      <p className='h2 text-center mx-auto'>
        Une expérience <span className='text-ih-blue'>simple</span> & <span className='text-ih-blue'>sans friction</span>
      </p>

      <div className='flex flex-col md:flex-row justify-center gap-8 md:w-3/4 mx-auto'>
        {items.map((item, index) => {
          return(
            <div key={index} className='flex flex-col items-center gap-3'>
              <Box>
                <Emoji width='w-7' emojiSrc={item.emoji} />
              </Box>

              <h3 className='h3'>{item.title}</h3>

              <div className='text-sm text-center'>{item.text}</div>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default SimpleExperience;

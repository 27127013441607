import React from 'react';

const RightPriceInfos = () => {
  const items = [
    {
      number: "01",
      title: "Attirez le plus d'acquéreurs potentiels",
      content: <p className='text-sm'>90% des acquéreurs affinent leur recherche en fonction de leur <span className='font-semibold'>budget maximum.</span> Si votre bien est surestimé, il ne sera donc pas visible par ces acquéreurs potentiels.</p>
    },
    {
      number: "02",
      title: "Vendez dans un délai convenable",
      content: <p className='text-sm'><span className='font-semibold'>Estimer le bon prix de vente</span> d'un bien dès le début de la commercialisation <span className='font-semibold'>est le meilleur moyen de vendre au prix dans un délai convenable.</span></p>
    },
    {
      number: "03",
      title: "Conservez l'attractivité de votre bien",
      content: <p className='text-sm'>Votre bien génèrera davantage <span className='font-semibold'>d'attractivité</span> auprès des acheteurs s'il est publié <span className='font-semibold'>au bon prix</span> sur les portails immobiliers.</p>
    },
  ]

  return (
    <div className='flex flex-col gap-8 text-ih-indigo' data-testid='RightPriceInfos'>
      <p className='h2 text-center md:w-1/3 mx-auto'>L'importance d'afficher votre bien <span className='text-ih-blue'>au bon prix</span></p>

      <div className='grid grid-cols-1 md:grid-cols-3 md:px-10 gap-6'>
        {items.map((item, index) => {
          return (
            <div key={index} className='flex flex-col gap-3 border border-ih-blue rounded-3xl p-4 text-center bg-ih-purple-5'>
              <div className='flex justify-around items-center h-8 w-8 bg-ih-blue rounded-xl text-white mx-auto'>{item.number}</div>
              <p className='h3'>{item.title}</p>
              {item.content}
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default RightPriceInfos;

import { formatInTimeZone } from 'date-fns-tz';

// 06/02/24
export const shortDateAndYear = (date) => {
  const dateOptions = {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  };

  return new Date(date).toLocaleDateString('fr-FR', dateOptions)
}

// Lundi 1 janvier
export const longDate = ({ date, capitalized = false }) => {
  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  };

  let formatedDate = new Date(date).toLocaleDateString('fr-FR', options)
  if (capitalized) formatedDate = formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1);

  return formatedDate;
}

// Lundi 1 janvier à 19:30
export const longDateTime = ({ date, capitalized = false }) => {
  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };

  let formatedDate =  new Date(date).toLocaleDateString('fr-FR', options)
  if (capitalized) formatedDate = formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1);

  return formatedDate;
}

// 03/01/23 - 11:00 - 11:30
export const eventDate = (startTime, endTime) => {
  const dateOptions = {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  };

  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric'
  };

  return `${new Date(startTime).toLocaleDateString('fr-FR', dateOptions)}
          - ${new Date(startTime).toLocaleTimeString('fr-FR', timeOptions)}
          - ${new Date(endTime).toLocaleTimeString('fr-FR', timeOptions)}`
}

// 11:00
export const displayTime = (date) => {
  return new Date(date).toLocaleTimeString('fr-FR', {
    hour: '2-digit',
    minute: '2-digit',
  });
}

// 2023-01-01T11:00:00
export const shortDate = (date) => {
  const dateObj = new Date(date);
  const options = { day: 'numeric', month: 'numeric' };
  const formattedDate = dateObj.toLocaleDateString('fr-FR', options);
  return formattedDate
}

// 30 - 45 - ...
export const minutesBetweenDates = (date1, date2) => {
  const difference = Math.abs(date2 - date1);
  const minutes = Math.floor(difference / (1000 * 60));
  return minutes;
}

export const isoDateTime = (dateTime) => {
  return formatInTimeZone(dateTime, 'Europe/Paris', 'yyyy-MM-dd HH:mm:ss').replace(' ', 'T')
}

// il y a 30 minutes
export const timeSince = (dateTime) => {
  const now = new Date();
  const timeDifference = now - dateTime;
  const secondsInMs = 1000;
  const minutesInMs = secondsInMs * 60;
  const hoursInMs = minutesInMs * 60;
  const daysInMs = hoursInMs * 24;
  const weeksInMs = daysInMs * 7;
  const monthsInMs = daysInMs * 30;
  const yearsInMs = daysInMs * 365;

  if (timeDifference < secondsInMs) {
    return "à l'instant";
  } else if (timeDifference < minutesInMs) {
    const seconds = Math.floor(timeDifference / secondsInMs);
    return `il y a ${seconds} seconde${seconds > 1 ? 's' : ''}`;
  } else if (timeDifference < hoursInMs) {
    const minutes = Math.floor(timeDifference / minutesInMs);
    return `il y a ${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else if (timeDifference < daysInMs) {
    const hours = Math.floor(timeDifference / hoursInMs);
    return `il y a ${hours} heure${hours > 1 ? 's' : ''}`;
  } else if (timeDifference < weeksInMs) {
    const days = Math.floor(timeDifference / daysInMs);
    return `il y a ${days} jour${days > 1 ? 's' : ''}`;
  } else if (timeDifference < monthsInMs) {
    const weeks = Math.floor(timeDifference / weeksInMs);
    return `il y a ${weeks} semaine${weeks > 1 ? 's' : ''}`;
  } else if (timeDifference < yearsInMs) {
    const months = Math.floor(timeDifference / monthsInMs);
    return `il y a ${months} mois`;
  } else {
    const day = ('0' + dateTime.getDate()).slice(-2);
    const month = ('0' + (dateTime.getMonth() + 1)).slice(-2);
    const year = dateTime.getFullYear().toString().slice(-2);
    return `le ${day}/${month}/${year}`;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'hosman-material';
import { definePremium } from '../../../../../utils/helpers/hosmanVars';
import { jinka, seloger, logicImmo, figaroImmo, leboncoin, bienici, bellesDemeures, proprieteLeFigaro, luxResidence, homeselect,
         jerevedunemaison, masteos, monchasseurImmo, perlerare, beanstock } from '../../../../../assets/logos'

const RealEstatePortals = ({ sale }) => {
  const premium = definePremium(sale.region.name_raw === 'Paris', sale.estimation_medium)

  const allPortals = [
    { logoSrc: jinka, altText: 'jinka' },
    { logoSrc: leboncoin, altText: 'leboncoin' },
    { logoSrc: bienici, altText: 'bienici' },
  ]

  const classicPortals = [
    { logoSrc: seloger, altText: 'seloger' },
    { logoSrc: logicImmo, altText: 'logicImmo' },
    { logoSrc: figaroImmo, altText: 'figaroImmo' },
  ]

  const premiumPortals = [
    { logoSrc: bellesDemeures, altText: 'bellesDemeures' },
    { logoSrc: proprieteLeFigaro, altText: 'proprieteLeFigaro' },
    { logoSrc: luxResidence, altText: 'luxResidence' },
  ]

  if (premium) {
    allPortals.splice(0, 0, ...premiumPortals);
  } else {
    allPortals.splice(1, 0, ...classicPortals);
  }

  const hunters = [
    { logoSrc: masteos, altText: 'masteos' },
    { logoSrc: monchasseurImmo, altText: 'monchasseurImmo' },
    { logoSrc: homeselect, altText: 'homeselect' },
    { logoSrc: jerevedunemaison, altText: 'jerevedunemaison' },
    { logoSrc: perlerare, altText: 'perlerare' },
    { logoSrc: beanstock, altText: 'beanstock' },
  ]

  return(
    <div className='flex flex-col gap-8'>
      <p className='h2 text-center md:w-1/2 mx-auto'>
        Une grande <span className='text-ih-blue'>force de diffusion</span>,
        sur + de <span className='text-ih-blue'>40 portails immobiliers</span>
      </p>

      <div className='grid grid-cols-1 md:grid-cols-2 justify-center gap-8 md:w-5/6 mx-auto'>
        <Card>
          <div className='flex flex-col gap-4 text-center p-8'>
            <div className='flex flex-col gap-2'>
              <p className='h3'>Sur les plus grands sites immobiliers</p>

              <p className='md:text-sm font-medium'>Votre annonce mise en avant sur <span className='text-ih-blue'>+ de 40 portails</span></p>
            </div>
            <div className='flex flex-wrap justify-around items-center gap-4'>
              {allPortals.map(({ logoSrc, altText }, i) => {
                return (
                  <img key={i} src={logoSrc} alt={altText} className='w-2/5' />
                )
              })}
            </div>
          </div>
        </Card>

        <Card>
          <div className='flex flex-col gap-4 text-center p-8'>
            <div className='flex flex-col gap-2'>
              <p className='h3'>Auprès de professionnels qualifiés</p>

              <p className='md:text-sm font-medium'><span className='text-ih-blue'>+100</span> chasseurs et investisseurs immobilier</p>
            </div>
            <div className='flex flex-wrap justify-around items-center gap-4'>
              {hunters.map(({ logoSrc, classes, altText }, i) => {
                return (
                  <img key={i} src={logoSrc} alt={altText} className='w-2/5' />
                )
              })}
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
};

export default RealEstatePortals;

// PropTypes
RealEstatePortals.propTypes = {
  sale: PropTypes.object
}

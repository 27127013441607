import React from 'react';

class ErrorsBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    let data = {
      app: 'Apps',
      error: error.toString(),
      url: window.location.href,
      tracker_id: localStorage.userId
    };

    fetch(`${window.location.origin}/api/v1/slacks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        channel: this.props.channel || 'FRONT_MONITORING_CHANNEL',
        data: data
      }),
      credentials: 'same-origin'
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='bg-hosman-gradient-1 flex justify-around items-center h-screen'>
          <div className='text-center md:w-1/2'>
            <p className='h1 text-ih-indigo'>
              Oups... Il semblerait qu'il y ait un problème. Nos équipes mettent tout en œuvre pour résoudre ceci <span className='text-ih-blue'>au plus vite !</span>
            </p>
            <p className='text-ih-indigo-80 mt-10'>
              Vous allez être redirigé vers la page principale.
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorsBoundary;

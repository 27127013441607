import React from 'react';

import { Card, Avatar } from 'hosman-material'

const BuyerAlertBase = () => {
  const handleRandomLetter = () => {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    return alphabet[randomIndex];
  }

  const randomInitials = () => {
    return `${handleRandomLetter().toLocaleUpperCase()} ${handleRandomLetter().toLocaleUpperCase()}`
  }

  return(
    <div className='w-full md:w-max mx-auto'>
      <Card>
        <div className='flex flex-col p-5'>
          <p className='h3 text-center mx-auto mb-1'>Auprès de notre fichier client</p>

          <div className='text-center mx-auto font-medium mb-7'>
            <span className='text-ih-blue'>+ de 25 000</span> acquéreurs potentiels actifs
          </div>

          <div className='flex justify-center gap-2 flex-wrap'>
            {Array.from({ length: 5 }, (_, index) =>
              <Avatar key={index} userName={randomInitials()} />
            )}
            <div className='w-12 h-12 rounded-full bg-ih-indigo'>
              <p className='h-12 flex justify-center items-center text-white h4'>+25k</p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
};

export default BuyerAlertBase;

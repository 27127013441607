import { toast } from 'react-hot-toast';
import { types } from './whitelist';

export const errorsInterceptor = ({ dispatch }) => (next) => (action, state) => {
  if (!action?.error) return next(action);

  const redirectToRoot = () => {
    setTimeout(() => {
      window.location = window.location
    }, 3000);
  }

  if (types.includes(action.type)) {
    next(action)
  } else {
    switch (action.error.message) {
      case 'Request failed with status code 404':
        redirectToRoot();
        toast.error('Page introuvable', { duration: 2000 })
        return
      case 'Request failed with status code 403':
        redirectToRoot();
        toast.error("Vous n'avez pas accès à cette page", { duration: 2000 })
        return
      case 'Request failed with status code 401':
        // If request is unauthorized we force refresh to generate a new token from rails
        redirectToRoot();
        return
      default:
        next(action)
    }
  }
}

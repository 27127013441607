import React from 'react';
import PropTypes from 'prop-types';

import YanportProperties from '../components/YanportProperties';
import HosmanProperties from '../components/HosmanProperties';

const ForSaleProperties = ({ sale, ypProperties, comparableSales }) => {
  const hosmanProperties = comparableSales.filter((cSale) => cSale.sale_state === 'awaiting_offer')

  return (
    <div className='flex flex-col gap-6 text-ih-indigo' data-testid='ForSaleProperties'>
      <div className='flex flex-col gap-20'>
        {ypProperties.length > 0 &&
          <>
            <div className='w-max mx-auto'>
              <p className='h1'>En vente par des agences</p>
            </div>
            <YanportProperties properties={ypProperties} />
          </>
        }

        {hosmanProperties.length > 0 &&
          <div className='flex flex-col gap-8'>
            <p className='h2 text-center mx-auto'>Les biens Hosman en vente <span className='text-ih-blue'>dans votre secteur</span></p>

            <HosmanProperties
              sale={sale}
              comparableSales={hosmanProperties}
            />
          </div>
        }
      </div>
    </div>
  );
}

export default ForSaleProperties;

ForSaleProperties.propTypes = {
  properties: PropTypes.array,
  comparableSales: PropTypes.array
};

import React from 'react';
import PropTypes from 'prop-types';

import HosmanProperties from '../components/HosmanProperties';

const SoldProperties = ({ sale, similarSalesScreenshots, comparableSales }) => {
  const hosmanProperties = comparableSales.filter((cSale) => ['awaiting_sale_agreement', 'awaiting_notarial_deed', 'sold'].includes(cSale.sale_state))

  return (
    <div className='flex flex-col gap-6 text-ih-indigo' data-testid='SoldProperties'>
      {similarSalesScreenshots.length > 0 &&
        <>
          <div className='w-max mx-auto'>
            <p className='h1'>Vendus par des agences</p>
          </div>
          <div className='flex flex-col gap-8 p-3 rounded'>
            {similarSalesScreenshots.filter(screenshot => screenshot.source === 'inventory').length > 0 &&
              <div className='flex flex-col gap-3'>
                {similarSalesScreenshots.filter(screenshot => screenshot.source === 'inventory').map((screenshot) => {
                  return (
                    <img key={screenshot.id} src={screenshot.screenshot_url.replace('.png', '.webp')} className='md:w-2/3 mx-auto rounded' />
                  )
                })}

                <p className='h4 text-center'>Source : Meilleurs Agents - Prix affiché net vendeur</p>
              </div>
            }

            {similarSalesScreenshots.filter(screenshot => screenshot.source === 'patrim').length > 0 &&
              <div className='flex flex-col gap-3 px-6'>
                {similarSalesScreenshots.filter(screenshot => screenshot.source === 'patrim').map((screenshot) => {
                  return (
                    <img key={screenshot.id} src={screenshot.screenshot_url.replace('.png', '.webp')} className='mx-auto' />
                  )
                })}

                <p className='h4 text-center'>Source: Base de données des valeurs foncières - Prix affiché net vendeur</p>
              </div>
            }
          </div>
        </>
      }

      {hosmanProperties.length > 0 &&
        <div className='flex flex-col gap-8'>
          <p className='h2 text-center mx-auto'>Hosman a déjà vendu <span className='text-ih-blue'>dans votre secteur</span></p>

          <HosmanProperties
            sale={sale}
            comparableSales={hosmanProperties}
          />
        </div>
      }
    </div>
  );
}

export default SoldProperties;

SoldProperties.propTypes = {
  sale: PropTypes.object.isRequired,
  comparableSales: PropTypes.array,
  similarSalesScreenshots: PropTypes.array,
};

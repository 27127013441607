import React from 'react';
import PropTypes from 'prop-types';

const Characteristics = ({ property, short = false }) => {
  const displayFloor = () => {
    if (property.kind === 'house' || property.kind === 'parking') return;
    if (!property.floor || !property.total_floor) return;

    let displayedFloor = ''
    if (property.floor === 0) {
      displayedFloor = 'RDC'
    } else if (property.floor === 1) {
      displayedFloor = `1er/${property.total_floor} ${short ? 'et.' : `étage${property.total_floor > 1 ? 's' : ''}`}`
    } else {
      displayedFloor = `${property.floor}e/${property.total_floor} ${short ? 'et.' : `étage${property.total_floor > 1 ? 's' : ''}`}`
    }

    return displayedFloor
  }

  return (
    <div className='flex gap-3 divide-x-2 divide-ih-purple-30 text-sm'>
      {(property.carrez_area || property.area) &&
        <p>{property.carrez_area ? Math.round(property.carrez_area) : Math.round(property.area)}m²</p>
      }

      {property.room_number && <p className='pl-3'>{property.room_number} {short ? 'p' : property.room_number > 1 ? 'pièces' : 'pièce'}</p>}

      {displayFloor() && <p className='pl-3'>{displayFloor()}</p>}

      {property.bedroom_number > 0 &&
        <p className='pl-3'>
          <span className='md:hidden'>{property.bedroom_number} ch</span>
          <span className='hidden md:block'>{property.bedroom_number} {short ? 'ch' : property.bedroom_number > 1 ? 'chambres' : 'chambre'}</span>
        </p>
      }
    </div>
  );
};

export default Characteristics;

Characteristics.propTypes = {
  property: PropTypes.object,
  short: PropTypes.bool
};

import client from '../client';

const VALIDATIONS = {
  phoneNumber(params) {
    return client.post(`/phones/verify`, params)
  },

  vouchers(value) {
    return client.get(`/vouchers`, { voucher: value })
  }
}

export default VALIDATIONS;

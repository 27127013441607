import React from 'react';
import { Button } from 'hosman-material';

const Error404 = () => {
  const backToHomePage = () => {
    window.location = (`
      ${window.location.href
        .replace('app.', '')
        .replace('admin.', '')
        .replace(window.location.pathname, '')}
      `)
  }

  return (
    <div className='bg-hosman-gradient-1 flex justify-around items-center h-screen'>
      <div className='text-center md:w-1/2'>
        <p className='h1 text-ih-indigo pb-6'>
          Oups... La page que vous recherchez <span className='text-ih-blue'>semble introuvable...</span>
        </p>

        <Button label='Retour sur hosman.co' onClick={backToHomePage} />
      </div>
    </div>
  )
}

export default Error404;

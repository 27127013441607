import axios from 'axios';
const querystring = require('querystring');

const isProd = window.location.host.includes('hosman.co') && !window.location.host.includes('staging')
let BASE_URL = `${window.location.origin.replace('app.', '').replace('admin.', '')}/api/v1`;
if (isProd) BASE_URL = BASE_URL.replace('hosman.co', 'www.hosman.co')

const config = (otherOptions = {}) => {
  const baseConfig = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'X-Auth-Token': window.localStorage.getItem('token')
    }
  }

  return Object.assign({}, baseConfig, otherOptions)
};

const client = {
  get(path, params) {
    return axios.get(`${BASE_URL}${path}?${querystring.stringify(params)}`, config())
  },

  post(path, params, customConfig) {
    return axios.post(`${BASE_URL}${path}`, params, config(customConfig))
  },

  delete(path) {
    return axios.delete(`${BASE_URL}${path}`, config())
  },
}

export default client;

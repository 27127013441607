import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { S_LocationDot, TextInput } from 'hosman-material';

const mapApiUrl = 'https://maps.googleapis.com/maps/api/js';
const geocoderUrl = 'https://maps.googleapis.com/maps/api/geocode/json';

const GeocoderInput = ({ name = 'address', value, label, onChange, errors = {}, placeholder, listPlacement = 'top-10' }) => {
  const [stateValue, setStateValue] = useState(value || '');
  const [predictions, setPredictions] = useState([]);
  const [displayPredictions, setDisplayPredictions] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    initMapScript().then(() => initAutocomplete())
  }, [stateValue]);

  useEffect(() => {
    setStateValue(value || '')
  }, [value]);

  useEffect(() => {
    // Close prediction list when clicking outside input
    window.onclick = () => {
      setDisplayPredictions(false)
    };
  }, []);

  function initAutocomplete() {
    if (stateValue.length < 2) setPredictions([])

    const displaySuggestions = function (predictions, status) {
      if (document.activeElement === inputRef.current) setDisplayPredictions(true)
      if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) return;

      setPredictions(predictions.map(prediction => prediction.description))
    };

    const service = new google.maps.places.AutocompleteService();

    service.getPlacePredictions({ input: stateValue, types: ['address'], componentRestrictions: { country: 'fr' } }, displaySuggestions);
  }

  const selectOption = async (prediction) => {
    setDisplayPredictions(false)
    const result = await fetchAddressData(prediction)
    const addressObj = extractAddress(name, result)

    setStateValue(addressObj[name]);
    onChange(addressObj);
  }

  const handleChange = (e) => {
    setStateValue(e.target.value)
    onChange({ [name]: e.target.value })
  }

  return (
    <div className='relative' data-testid='GeocoderInput'>
      <TextInput
        refProp={inputRef}
        name={name}
        label={label}
        value={stateValue}
        onChange={handleChange}
        errors = {errors}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        paddings='pl-8'
      />

      <FontAwesomeIcon icon={S_LocationDot} className={`text-ih-purple-40 absolute left-3 ${label ? 'top-[53px]' : 'top-3'} mt-0.5 text-lg`} />

      {displayPredictions && predictions.length > 0 &&
        <ul className={`z-50 absolute w-full bg-white rounded-2xl border border-ih-purple-20 overflow-y-scroll mt-2 ${listPlacement}`}>
          {predictions.map(prediction => {
            return (
              <li
                key={prediction}
                onClick={() => selectOption(prediction)}
                className='text-ih-indigo first:rounded-t-2xl last:rounded-b-2xl hover:bg-ih-purple-5 cursor-pointer select-none relative py-3 px-3 border-b border-b-ih-purple-10'
              >
                {prediction}
              </li>
            )
          })}
        </ul>
      }
    </div>
  );
};

function initMapScript() {
  if (window.google) {
    return Promise.resolve();
  }

  window.initMap = function() {};
  const src = `${mapApiUrl}?key=${process.env.GOOGLE_API_BROWSER_KEY}&libraries=places&language=fr&callback=initMap`;
  return loadAsyncScript(src);
}

function loadAsyncScript(src) {
  return new Promise(resolve => {
    const script = document.createElement('script');
    Object.assign(script, {
      type: 'text/javascript',
      async: true,
      src
    })
    script.addEventListener('load', () => resolve(script));
    document.head.appendChild(script);
  })
}

function fetchAddressData(prediction) {
  const result = fetch(`${geocoderUrl}?address=${prediction}&region=fr&key=${process.env.GOOGLE_API_SERVER_KEY}`)

  return result.then((r) => r.json().then(jsonData => jsonData.results[0]));
}

function extractAddress(name, place) {
  const addressComponents = place.address_components
  const addressInfos = {
    [name]: place.formatted_address,
    street_number: addressComponents.find(t => t.types[0].includes('street_number'))?.long_name,
    street_name: addressComponents.find(t => t.types[0].includes('route'))?.long_name,
    city: addressComponents.find(t => t.types[0].includes('locality'))?.long_name,
    zip_code: addressComponents.find(t => t.types[0].includes('postal_code'))?.long_name,
    country_code: addressComponents.find(t => t.types[0].includes('country'))?.short_name,
    lat: place.geometry.location.lat,
    lng: place.geometry.location.lng
  }

  return addressInfos;
}

export default GeocoderInput;

GeocoderInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  placeholder: PropTypes.string,
  listPlacement: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';

import TitleBanner from '../../components/TitleBanner';
import RightPriceInfos from './components/RightPriceInfos';
import Estimations from './components/Estimations';
import HosmanOffer from './components/HosmanOffer';
import PotentialBuyers from './components/PotentialBuyers';

const Estimation = ({ estimationFolder }) => {
  return (
    <div className='flex flex-col gap-12' data-testid='Estimation'>
      <TitleBanner number='04' title='Votre estimation' />

      <div className='flex flex-col gap-16 pb-20'>
        <RightPriceInfos />

        <Estimations estimationFolder={estimationFolder} />

        <HosmanOffer sale={estimationFolder.sale} />

        <PotentialBuyers estimationFolder={estimationFolder} />
      </div>
    </div>
  );
}

export default Estimation;

Estimation.propTypes = {
  estimationFolder: PropTypes.object.isRequired
}

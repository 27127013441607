import React from 'react';

class ErrorsBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    let data = {
      app: 'Main',
      error: error.toString(),
      url: window.location.href
    };

    fetch(`${window.location.origin}/api/v1/slacks`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        channel: 'FRONT_MONITORING_CHANNEL',
        data: data
      }),
      credentials: 'same-origin'
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='container vesta-margin-y-60 md:vesta-margin-y-80'>
          <div className='row error-box vesta-text-center'>
            <div className='col-xs-12 vesta-page-xs-padding'>
              <div className='vesta-margin-t-80'>
                <h2 className='typo-h2 vesta-margin-b-15'>
                  Oups... Il semblerait <span className='vesta-blue-90'>qu'il y ait un problème.</span> Nos équipes mettent tout en œuvre pour résoudre ceci <span className='vesta-blue-90'>au plus vite.</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorsBoundary;

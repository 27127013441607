import React from 'react';
import PropTypes from 'prop-types';

import Introduction from './views/Introduction';
import Property from './views/Property';
import MarketAnalysis from './views/MarketAnalysis';
import ComparableSales from './views/ComparableSales';
import Estimation from './views/Estimation';
import NextSteps from './views/NextSteps';
import WhyHosman from './views/WhyHosman';

const EstimationFolderView = React.memo(({ sectionRefs, estimationFolder, comparableSales, similarSalesScreenshots }) => {
  const handleRef = (ref, index) => {
    if (!sectionRefs) return;

    sectionRefs.current[index] = ref
  }

  const views = [
    { id: 'introduction', view: Introduction },
    { id: 'votre-bien', view: Property },
    { id: 'analyse-du-marche', view: MarketAnalysis },
    { id: 'biens-comparables', view: ComparableSales },
    { id: 'votre-estimation', view: Estimation },
    { id: 'prochaines-etapes', view: NextSteps },
    { id: 'pourquoi-hosman', view: WhyHosman },
  ];
  return (
    <div className='flex flex-col' data-testid='EstimationFolderView'>
      {views.map((view, index) => (
        <div key={view.id} className='flex flex-col'>
          <div id={view.id} ref={(ref) => handleRef(ref, index)}></div>
          <view.view
            estimationFolder={estimationFolder}
            comparableSales={comparableSales}
            similarSalesScreenshots={similarSalesScreenshots}
          />
        </div>
      ))}
    </div>
  );
})

export default EstimationFolderView;

EstimationFolderView.propTypes = {
  sectionRefs: PropTypes.object,
  estimationFolder: PropTypes.object.isRequired,
  comparableSales: PropTypes.array,
  similarSalesScreenshots: PropTypes.array
};

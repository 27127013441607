import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_AngleRight, S_AngleLeft } from 'hosman-material';
import { clappingHands } from '../../../../../assets/emojis'
import { trustpilot, trustpilot_five_stars } from '../../../../../assets/logos'

import { Emoji, Avatar, Card, Separator } from 'hosman-material'

import TRUSTPILOT_REVIEWS from '../../../../../apiPublic/ressources/trustpilotReviews'

const TrustpilotReviews = ({ expertId }) => {
  const [reviews, setReviews] = useState([])
  const [activeReview, setActiveReview] = useState({})
  const reviewContainerRef = useRef(null)

  useEffect(() => {
    TRUSTPILOT_REVIEWS.getExpertReviews(expertId, { limit: 3 }).then((response) => {
      setReviews(response.data)
      setActiveReview(response.data[0])
    });
  }, []);

  const handleReviewChange = (action) => {
    reviewContainerRef.current.classList.remove('opacity-100');
    reviewContainerRef.current.classList.add('opacity-0');

    setTimeout(() => {
      const activeIndex = reviews.indexOf(reviews.find(review => review.id === activeReview.id))

      if (action === 'next')
        activeIndex === reviews.length-1 ? setActiveReview(reviews[0]) : setActiveReview(reviews[activeIndex+1])

      if (action === 'past')
        activeIndex === 0 ? setActiveReview(reviews[reviews.length - 1]) : setActiveReview(reviews[activeIndex-1])

      reviewContainerRef.current.classList.add('opacity-100');
      reviewContainerRef.current.classList.remove('opacity-0');
    }, 200);
  }

  return(
    <div className='flex flex-col gap-6 bg-hosman-gradient-1 py-10'>
      <a href='https://fr-be.trustpilot.com/review/www.hosman.co' className='text-center mx-auto'>
        <Emoji emojiSrc={clappingHands} width='w-10' />

        <h2 className='h2'>
          Un prix juste pour un <br />
          <span className='text-ih-blue'>service 5 étoiles !</span>
        </h2>

        <div className='flex justify-center items-center gap-2 font-medium'>
          4,9/5 Excellent <img src={trustpilot} alt='trustpilot' className='w-28 h-7'/>
        </div>
      </a>

      <div ref={reviewContainerRef} className='flex justify-center gap-7 w-5/6 mx-auto'>
        {reviews.map(({ id, user_full_name, user_review_title, user_review_content, expert_name }) => (
          <div key={id} className={`w-full ${activeReview.id !== id ? 'hidden md:block' : ''}`}>
            <Card>
              <div className='flex flex-col gap-4 p-5'>
                <div className='flex gap-3'>
                  <Avatar bgColor='bg-ih-purple-10' textColor='text-ih-indigo' userName={user_full_name} />

                  <div className='flex flex-col gap-1'>
                    <h4 className='h4 text-ih-indigo'>{user_full_name.split(' ')[0]}</h4>

                    <p className='text-ih-purple-50 text-sm'>Vendu par {expert_name}</p>
                  </div>
                </div>

                <Separator margins='my-2' borderColor='border-ih-purple-10' />

                <div className='flex flex-col gap-3 text-sm'>
                  <img src={trustpilot_five_stars} alt='trustpilot_five_stars' className='w-36 h-7' />

                  <div className='flex flex-col gap-3 max-h-[200px] overflow-scroll'>
                    <p className='font-semibold'>{user_review_title}</p>
                    <p className='whitespace-pre-line'>{user_review_content}</p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>

      <div className='flex justify-center gap-4 mt-5 md:hidden'>
        <span onClick={() => handleReviewChange('past')} className='inline-flex justify-center items-center w-10 h-10 rounded-full bg-ih-indigo'>
          <FontAwesomeIcon icon={S_AngleLeft} className='text-white' />
        </span>

        <span onClick={() => handleReviewChange('next')} className='inline-flex justify-center items-center w-10 h-10 rounded-full bg-ih-indigo'>
          <FontAwesomeIcon icon={S_AngleRight} className='text-white' />
        </span>
      </div>
    </div>
  )
};

export default TrustpilotReviews;

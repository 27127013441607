import React from 'react';
import PropTypes from 'prop-types';
import { pricePerMeter, calculateAgencyFees, ponderatedPricePerMeter } from '../../../../../utils/helpers/hosmanVars'
import { InfoIcon } from 'hosman-material';

const Estimations = ({ estimationFolder }) => {
  const { sale: { property, region, estimation_low, estimation_medium, estimation_high } } = estimationFolder

  const calculateSellerPrice = (price) => {
    const fees = calculateAgencyFees(
      region.name_raw === 'Paris',
      ['house', 'mansion'].includes(property.kind),
      price,
      false
    )

    return (price - fees).toLocaleString()
  }

  if (!estimation_low || !estimation_medium || !estimation_high) return null;

  return (
    <div className='flex flex-col gap-8 text-ih-indigo bg-hosman-gradient-1 py-16' data-testid='Estimations'>
      <p className='h2 text-center w-2/3 md:w-1/2 mx-auto'><span className='text-ih-blue'>Voici l'estimation </span>de votre bien</p>

      <div className='grid grid-cols-1 md:grid-cols-3 gap-6 md:w-3/4 mx-auto'>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center gap-2 mx-auto'>
            <p className='font-semibold'>Estimation basse</p>
            <InfoIcon tooltip='Prix que nous recommandons pour trouver un acquéreur rapidement sans compromettre votre net vendeur' />
          </div>

          <div className='flex flex-col items-center py-5 rounded-2xl border border-ih-indigo bg-white'>
            <p className='h2'>{estimation_low.toLocaleString()} €</p>
            <p className='text-sm italic'>{pricePerMeter(estimation_low, property)}</p>
            { property.ponderated_area &&
              <p className='text-sm italic'>{ponderatedPricePerMeter(estimation_low, property)} pondéré</p>
            }
            <p className='text-sm'>Net vendeur : {calculateSellerPrice(estimation_low)} €</p>
          </div>
        </div>

        <div className='flex flex-col gap-4'>
          <div className='flex items-center gap-2 mx-auto'>
            <p className='font-semibold'>Prix de marché</p>
            <InfoIcon tooltip='Prix le plus cohérent par rapport aux spécificités de votre bien afin de vendre dans un délai moyen' />
          </div>

          <div className='flex flex-col items-center py-5 rounded-2xl border border-ih-blue bg-ih-blue text-white'>
            <p className='h2'>{estimation_medium.toLocaleString()} €</p>
            <p className='text-sm italic'>{pricePerMeter(estimation_medium, property)}</p>
            { property.ponderated_area &&
              <p className='text-sm italic'>{ponderatedPricePerMeter(estimation_medium, property)} pondéré</p>
            }
            <p className='text-sm'>Net vendeur : {calculateSellerPrice(estimation_medium)} €</p>
          </div>
        </div>

        <div className='flex flex-col gap-4'>
          <div className='flex items-center gap-2 mx-auto'>
            <p className='font-semibold'>Estimation haute</p>
            <InfoIcon tooltip='Le prix maximum que nous conseillons pour commercialiser' />
          </div>

          <div className='flex flex-col items-center py-5 rounded-2xl border border-ih-indigo bg-white'>
            <p className='h2'>{estimation_high.toLocaleString()} €</p>
            <p className='text-sm italic'>{pricePerMeter(estimation_high, property)}</p>
            { property.ponderated_area &&
              <p className='text-sm italic'>{ponderatedPricePerMeter(estimation_high, property)} pondéré</p>
            }
            <p className='text-sm'>Net vendeur : {calculateSellerPrice(estimation_high)} €</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Estimations;

// PropTypes
Estimations.propTypes = {
  estimationFolder: PropTypes.object.isRequired
}

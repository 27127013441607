import React from 'react';
import PropTypes from 'prop-types';

import { Map } from 'hosman-material';

const Sector = ({ property, quarterId, quarterDescription, quarterMapDisplay }) => {
  return (
    <div className='flex flex-col gap-8' data-testid='Sector'>
      <p className='h2 mx-auto text-center'>Votre <span className='text-ih-blue'>secteur</span> </p>

      <div className='flex flex-col md:flex-row items-center gap-8 md:w-3/4 mx-auto text-ih-indigo'>
        <div className={`w-full flex flex-col gap-5 ${quarterMapDisplay ? '' : 'text-center'}`}>
          <p className='h3'>Quelques mots sur votre secteur</p>
          <p className={`whitespace-pre-line ${quarterMapDisplay ? '' : 'md:w-2/3 mx-auto'}`}>{quarterDescription}</p>
        </div>

        {quarterMapDisplay &&
          <div className='w-full' data-testid='quarterMap'>
            {process.env.NODE_ENV !== 'test' &&
              <div className='cursor-pointer'>
                <Map
                  accessToken={process.env.MAPBOX_PUBLIC_KEY}
                  tilesetId={process.env.MAPBOX_TILESET_ID}
                  style={process.env.MAPBOX_STYLE}
                  center={{ lat: property.lat, lng: property.lng }}
                  zoom={12}
                  zone={{ className: 'Quarter', id: quarterId }}
                  marker
                  fixed
                />
              </div>
            }
          </div>
        }
      </div>
    </div>
  );
}

export default Sector;

// PropTypes
Sector.propTypes = {
  property: PropTypes.object.isRequired,
  quarterId: PropTypes.number,
  quarterDescription: PropTypes.string,
  quarterMapDisplay: PropTypes.bool
}

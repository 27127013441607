const distanceBetween = (lat1, lng1, lat2, lng2) => {
  const rayonTerre = 6371; // Rayon moyen de la Terre en kilomètres
  const degToRad = Math.PI / 180; // Conversion degrés en radians

  // Convertir les coordonnées en radians
  const latRad1 = lat1 * degToRad;
  const lonRad1 = lng1 * degToRad;
  const latRad2 = lat2 * degToRad;
  const lonRad2 = lng2 * degToRad;

  // Calcul des différences de latitude et de longitude
  const deltaLat = latRad2 - latRad1;
  const deltaLon = lonRad2 - lonRad1;

  // Calcul de la distance orthodromique en utilisant la formule de la trigonométrie sphérique (loi des cosinus)
  const a =
    Math.sin(deltaLat / 2) ** 2 +
    Math.cos(latRad1) *
    Math.cos(latRad2) *
    Math.sin(deltaLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let distance = rayonTerre * c * 1000; // Conversion en mètres

  if (distance < 1000) {
    return `${Math.round(distance / 50) * 50}m`;
  } else {
    return `${(distance/1000).toFixed(1)}km`;
  }
}

export { distanceBetween }

import React from 'react';
import PropTypes from 'prop-types';
import { partyingFace } from '../../../../../../shared/assets/emojis';
import { humanizedPropertyKind } from '../../../../../../shared/utils/helpers/hosmanVars';

import BuyerBaseGraph from '../../../components/BuyerBaseGraph';
import AvatarsCounter from '../../../../../../shared/components/AvatarsCounter';
import { Emoji } from 'hosman-material';

const PotentialBuyers = ({ estimationFolder }) => {
  const { buyers_base_display, potential_buyer_low, potential_buyer_medium, potential_buyer_high,
          sale: { property, estimation_low, estimation_medium, estimation_high } } = estimationFolder

  if (buyers_base_display === 'none' || potential_buyer_medium < 3) return null;

  return (
    <div className='flex flex-col gap-6' data-testid='PotentialBuyers'>
      <Emoji emojiSrc={partyingFace} width='w-8 mx-auto' />
      <p className='h2 text-center md:w-3/4 mx-auto'>Super ! Nous avons déjà trouvé <span className='text-ih-blue'>{potential_buyer_medium} acquéreurs potentiels </span>pour votre {humanizedPropertyKind(property).toLowerCase()}</p>

      {buyers_base_display === 'graph' &&
        <div className='flex md:w-3/4 mx-auto mt-10' data-testid='GraphContainer'>
          <BuyerBaseGraph
            items={{
              low: {estimation: estimation_low, buyerBase: potential_buyer_low},
              medium: {estimation: estimation_medium, buyerBase: potential_buyer_medium},
              high: {estimation: estimation_high, buyerBase: potential_buyer_high}
            }}
          />
        </div>
      }

      {buyers_base_display === 'volume' &&
        <div className='flex flex-col gap-5 text-center md:w-3/4 mx-auto' data-testid='VolumeContainer'>
          <p>
            Notre algorithme calcule en direct le volume d'acheteurs ayants renseignés des critères de recherche correspondant à votre bien. Avec Hosman, touchez rapidement une cible qualifiée et accélérez votre vente.
          </p>

          <AvatarsCounter count={potential_buyer_medium} />
        </div>
      }
    </div>
  );
}

export default PotentialBuyers;

// PropTypes
PotentialBuyers.propTypes = {
  estimationFolder: PropTypes.object.isRequired
}

import React from 'react';

import TitleBanner from '../../components/TitleBanner';
import { nextSteps } from './stepsContent';

import { NextStepsCarousel } from 'hosman-material';

const NextSteps = () => {
  return (
    <div className='flex flex-col gap-12' data-testid='NextSteps'>
      <TitleBanner number='05' title='Prochaines étapes' />

      <div className='flex flex-col gap-8 text-ih-indigo pb-20'>
        <p className='h2 text-center md:w-1/3 mx-auto'>Les <span className='text-ih-blue'>prochaines étapes </span>de votre vente</p>

        <NextStepsCarousel nextSteps={nextSteps} />
      </div>
    </div>
  );
}

export default NextSteps;

// parisRegion, houseKind and noExclusivity are booleans, price is an Integer
export function agencyFees(parisRegion, houseKind, price, noExclusivity) {
  let feesAmount = 0;

  if (parisRegion && houseKind) { feesAmount = parisRegionHouseFees(price) }
  else if (parisRegion) { feesAmount = parisRegionApartmentFees(price) }
  else if (houseKind) { feesAmount = otherRegionHouseFees(price) }
  else { feesAmount = otherRegionApartmentFees(price) };

  if (noExclusivity) { feesAmount += 3000 };

  return feesAmount;
}

function parisRegionApartmentFees(price) {
  if (price <= 300000) { return 6900 }
  else if (price <= 500000) { return 7900 }
  else if (price <= 850000) { return 9900 }
  else if (price <= 1000000) { return 14900 }
  else if (price <= 2000000) { return 19900 }
  else { return price * 0.02 }
}

function parisRegionHouseFees(price) {
  if (price <= 300000) { return 8900 }
  else if (price <= 500000) { return 9900 }
  else if (price <= 850000) { return 11900 }
  else if (price <= 1000000) { return 15900 }
  else if (price <= 2000000) { return 19900 }
  else { return price * 0.02 }
}

function otherRegionApartmentFees(price) {
  if (price <= 200000) { return 5900 }
  else if (price <= 500000) { return 6900 }
  else if (price <= 800000) { return 9900 }
  else if (price <= 1000000) { return 13900 }
  else if (price <= 2000000) { return 19900 }
  else { return price * 0.02 }
}

function otherRegionHouseFees(price) {
  if (price <= 200000) { return 6900 }
  else if (price <= 500000) { return 8900 }
  else if (price <= 800000) { return 11900 }
  else if (price <= 1000000) { return 15900 }
  else if (price <= 2000000) { return 19900 }
  else { return price * 0.02 }
}

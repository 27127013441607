// FACES
export const smilingFace = 'https://res.cloudinary.com/vesta-home/image/upload/v1670341118/assets/Napoleon/Emojis/smilingFace.svg';
export const partyingFace = 'https://res.cloudinary.com/vesta-home/image/upload/v1670341118/assets/Napoleon/Emojis/partyingFace.svg';
export const disappointedFace = 'https://res.cloudinary.com/vesta-home/image/upload/v1670341118/assets/Napoleon/Emojis/disappointedFace.svg';
export const starStruck = 'https://res.cloudinary.com/vesta-home/image/upload/v1670946391/assets/Napoleon/Emojis/starStruck.svg';
export const eyes = 'https://res.cloudinary.com/vesta-home/image/upload/v1670341118/assets/Napoleon/Emojis/eyes.svg';
export const womanTechnologist = 'https://res.cloudinary.com/vesta-home/image/upload/v1689242525/assets/Napoleon/Emojis/womanTechnologist.svg'
export const winkingFace = 'https://res.cloudinary.com/vesta-home/image/upload/v1690537779/assets/Napoleon/Emojis/winking_face.svg';

// FINGERS & HANDS
export const indexPointingRight = 'https://res.cloudinary.com/vesta-home/image/upload/v1670341119/assets/Napoleon/Emojis/indexPointingRight.svg';
export const crossedFingers = 'https://res.cloudinary.com/vesta-home/image/upload/v1670341118/assets/Napoleon/Emojis/crossedFingers.svg';
export const clappingHands = 'https://res.cloudinary.com/vesta-home/image/upload/v1670342822/assets/Napoleon/Emojis/clappingHands.svg';
export const handShake = 'https://res.cloudinary.com/vesta-home/image/upload/v1670342823/assets/Napoleon/Emojis/handShake.svg';
export const raisingHands = 'https://res.cloudinary.com/vesta-home/image/upload/v1689242524/assets/Napoleon/Emojis/raisingHands.svg'

// HOUSE
export const house = 'https://res.cloudinary.com/vesta-home/image/upload/v1670341118/assets/Napoleon/Emojis/house.svg';
export const houseWithGarden = 'https://res.cloudinary.com/vesta-home/image/upload/v1680017074/assets/Napoleon/Emojis/house-with-garden.svg'
export const houses = 'https://res.cloudinary.com/vesta-home/image/upload/v1680017120/assets/Napoleon/Emojis/houses.svg'
export const building = 'https://res.cloudinary.com/vesta-home/image/upload/v1673351733/assets/Napoleon/Emojis/building.svg';
export const houseDrawing = 'https://res.cloudinary.com/vesta-home/image/upload/v1712666263/assets/images/icons/houseDrawing.svg'
export const buildingDrawing = 'https://res.cloudinary.com/vesta-home/image/upload/v1712666265/assets/images/icons/buildingDrawing.svg';

// NATURE
export const sun = 'https://res.cloudinary.com/vesta-home/image/upload/v1670342823/assets/Napoleon/Emojis/sun.svg';
export const highVoltage = 'https://res.cloudinary.com/vesta-home/image/upload/v1689242639/assets/Napoleon/Emojis/highVoltage.svg'

// OTHERS
export const camera = 'https://res.cloudinary.com/vesta-home/image/upload/v1670342823/assets/Napoleon/Emojis/camera.svg';
export const folder = 'https://res.cloudinary.com/vesta-home/image/upload/v1670342823/assets/Napoleon/Emojis/folder.svg';
export const triangularRuler = 'https://res.cloudinary.com/vesta-home/image/upload/v1673351885/assets/Napoleon/Emojis/triangularRuler.svg';
export const glowingStar = 'https://res.cloudinary.com/vesta-home/image/upload/v1687263890/assets/Napoleon/Emojis/glowingStar.svg';

import React from 'react';
import { partyingFace, winkingFace } from '../../../shared/assets/emojis';
import { generateLink } from '../../../shared/utils/railsRoutes';
import {useLocation} from 'react-router-dom';

import OfferPriceCards from './components/OfferPriceCards';

import { Button, Card, InfoBox, Emoji } from 'hosman-material';

const SponsorshipCard = ({ avatarSrc }) => {
  let location = useLocation();

  return (
    <div data-testid='SponsorshipCard'>
      <Card>
        <div className='flex flex-col gap-12 px-6 py-12'>
          <div className='flex flex-col-reverse md:flex-row justify-center items-center gap-3 text-center text-ih-indigo'>
            <h2 className='h2'>Parrainez un proche, <span className='text-ih-blue'>gagnez jusqu'à 600€</span></h2>

            <Emoji emojiSrc={partyingFace} width='w-8' />
          </div>

          <div className='flex flex-col gap-8'>
            <div className='flex justify-center flex-wrap gap-7'>
              <OfferPriceCards bgColor='bg-ih-green-5'/>
            </div>

            <div className='text-center w-fit mx-auto'>
              <a href={generateLink(RailsRoutes.parrainage_new_path({ utm_source: 'espacehosman', utm_medium: location.pathname.includes('/ea/') ? 'acheteur' : 'vendeur', utm_campaign: 'parrainage' }))} target='_blank'>
                <Button label="Découvrir l'offre parrainage" />
              </a>
            </div>
          </div>


          <div className='w-fit mx-auto'>
            <InfoBox avatarSrc={avatarSrc}>
              <p>Ces bénéfices sont valables pour chaque parrainage effectué. Plus vous parrainez, plus vous gagnez !
                <img src={winkingFace} width='22' height='22' alt='check' className='inline ml-2' />
              </p>
            </InfoBox>
          </div>
        </div>
      </Card>
    </div>
  )
};

export default SponsorshipCard;
